import React, { useState, useEffect } from 'react';
import {
  Box,
  Heading,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  VStack,
  FormControl,
  FormLabel,
  Input,
  Button,
  useToast,
} from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { getJengaApiConfig, updateJengaApiConfig } from '../actions/jengaApiActions';

const IntegrationsScreen = () => {
  const dispatch = useDispatch();
  const toast = useToast();

  const [bankConfig, setBankConfig] = useState({
    merchantCode: '',
    consumerSecret: '',
    apiKey: '',
  });

  const [mpesaConfig, setMpesaConfig] = useState({
    shortCode: '',
    consumerKey: '',
    consumerSecret: '',
  });

  const jengaApiConfig = useSelector((state) => state.jengaApiConfig);
  const { loading, error, config } = jengaApiConfig;

  useEffect(() => {
    dispatch(getJengaApiConfig());
  }, [dispatch]);

  useEffect(() => {
    if (config) {
      setBankConfig({
        merchantCode: config.merchantCode || '',
        consumerSecret: config.consumerSecret || '',
        apiKey: config.apiKey || '',
      });
      setMpesaConfig({
        shortCode: config.mpesaShortCode || '',
        consumerKey: config.mpesaConsumerKey || '',
        consumerSecret: config.mpesaConsumerSecret || '',
      });
    }
  }, [config]);

  const handleBankSubmit = (e) => {
    e.preventDefault();
    dispatch(updateJengaApiConfig({ ...bankConfig, type: 'bank' }));
  };

  const handleMpesaSubmit = (e) => {
    e.preventDefault();
    dispatch(updateJengaApiConfig({ ...mpesaConfig, type: 'mpesa' }));
  };

  return (
    <Box>
      <Heading as="h1" size="xl" mb={6} color="teal.500">
        Integrations
      </Heading>
      <Tabs>
        <TabList>
          <Tab>Bank Integration</Tab>
          <Tab>Mpesa Integration</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <form onSubmit={handleBankSubmit}>
              <VStack spacing={4} align="stretch">
                <FormControl>
                  <FormLabel>Merchant Code</FormLabel>
                  <Input
                    value={bankConfig.merchantCode}
                    onChange={(e) => setBankConfig({ ...bankConfig, merchantCode: e.target.value })}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Consumer Secret</FormLabel>
                  <Input
                    type="password"
                    value={bankConfig.consumerSecret}
                    onChange={(e) => setBankConfig({ ...bankConfig, consumerSecret: e.target.value })}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>API Key</FormLabel>
                  <Input
                    type="password"
                    value={bankConfig.apiKey}
                    onChange={(e) => setBankConfig({ ...bankConfig, apiKey: e.target.value })}
                  />
                </FormControl>
                <Button type="submit" colorScheme="blue">
                  Save Bank Configuration
                </Button>
              </VStack>
            </form>
          </TabPanel>
          <TabPanel>
            <form onSubmit={handleMpesaSubmit}>
              <VStack spacing={4} align="stretch">
                <FormControl>
                  <FormLabel>Short Code</FormLabel>
                  <Input
                    value={mpesaConfig.shortCode}
                    onChange={(e) => setMpesaConfig({ ...mpesaConfig, shortCode: e.target.value })}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Consumer Key</FormLabel>
                  <Input
                    type="password"
                    value={mpesaConfig.consumerKey}
                    onChange={(e) => setMpesaConfig({ ...mpesaConfig, consumerKey: e.target.value })}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Consumer Secret</FormLabel>
                  <Input
                    type="password"
                    value={mpesaConfig.consumerSecret}
                    onChange={(e) => setMpesaConfig({ ...mpesaConfig, consumerSecret: e.target.value })}
                  />
                </FormControl>
                <Button type="submit" colorScheme="green">
                  Save Mpesa Configuration
                </Button>
              </VStack>
            </form>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default IntegrationsScreen;