export const JENGA_API_CONFIG_CREATE_REQUEST = 'JENGA_API_CONFIG_CREATE_REQUEST';
export const JENGA_API_CONFIG_CREATE_SUCCESS = 'JENGA_API_CONFIG_CREATE_SUCCESS';
export const JENGA_API_CONFIG_CREATE_FAIL = 'JENGA_API_CONFIG_CREATE_FAIL';

export const JENGA_API_CONFIG_GET_REQUEST = 'JENGA_API_CONFIG_GET_REQUEST';
export const JENGA_API_CONFIG_GET_SUCCESS = 'JENGA_API_CONFIG_GET_SUCCESS';
export const JENGA_API_CONFIG_GET_FAIL = 'JENGA_API_CONFIG_GET_FAIL';

export const JENGA_API_CONFIG_UPDATE_REQUEST = 'JENGA_API_CONFIG_UPDATE_REQUEST';
export const JENGA_API_CONFIG_UPDATE_SUCCESS = 'JENGA_API_CONFIG_UPDATE_SUCCESS';
export const JENGA_API_CONFIG_UPDATE_FAIL = 'JENGA_API_CONFIG_UPDATE_FAIL';