export const CONTRIBUTION_LIST_REQUEST = "CONTRIBUTION_LIST_REQUEST";
export const CONTRIBUTION_LIST_SUCCESS = "CONTRIBUTION_LIST_SUCCESS";
export const CONTRIBUTION_LIST_FAIL = "CONTRIBUTION_LIST_FAIL";

export const CONTRIBUTION_DETAILS_REQUEST = "CONTRIBUTION_DETAILS_REQUEST";
export const CONTRIBUTION_DETAILS_SUCCESS = "CONTRIBUTION_DETAILS_SUCCESS";
export const CONTRIBUTION_DETAILS_FAIL = "CONTRIBUTION_DETAILS_FAIL";

export const CONTRIBUTION_CREATE_REQUEST = "CONTRIBUTION_CREATE_REQUEST";
export const CONTRIBUTION_CREATE_SUCCESS = "CONTRIBUTION_CREATE_SUCCESS";
export const CONTRIBUTION_CREATE_FAIL = "CONTRIBUTION_CREATE_FAIL";
export const CONTRIBUTION_CREATE_RESET = "CONTRIBUTION_CREATE_RESET";

export const CONTRIBUTION_UPDATE_REQUEST = "CONTRIBUTION_UPDATE_REQUEST";
export const CONTRIBUTION_UPDATE_SUCCESS = "CONTRIBUTION_UPDATE_SUCCESS";
export const CONTRIBUTION_UPDATE_FAIL = "CONTRIBUTION_UPDATE_FAIL";
export const CONTRIBUTION_UPDATE_RESET = "CONTRIBUTION_UPDATE_RESET";

export const CONTRIBUTION_DELETE_REQUEST = "CONTRIBUTION_DELETE_REQUEST";
export const CONTRIBUTION_DELETE_SUCCESS = "CONTRIBUTION_DELETE_SUCCESS";
export const CONTRIBUTION_DELETE_FAIL = "CONTRIBUTION_DELETE_FAIL";
export const CONTRIBUTION_DELETE_RESET = "CONTRIBUTION_DELETE_RESET";
