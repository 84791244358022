import { Flex, Box } from "@chakra-ui/react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
// import Header from "./components/Header";
import Footer from "./components/Footer";
import HomeScreen from "./screens/HomeScreen";
import LoginScreen from "./screens/LoginScreen";
import RegisterScreen from "./components/UserForm";
import UserListScreen from "./screens/UserListScreen";
// import UserEditDrawer from "./screens/UserEditDrawer";
import ProfileScreen from "./screens/ProfileScreen";
import EventListScreen from "./screens/EventListScreen";
import ContributionListScreen from "./screens/ContributionListScreen";
import DashboardScreen from "./screens/DashboardScreen";
import LeftSideMenu from "./components/LeftSideMenu";
import IntegrationsScreen from './screens/IntegrationsScreen';
import AdminScreen from "./screens/AdminDashboardScreen";
import SettingsForm from "./components/SettingsForm";

const App = () => {
  return (
    <BrowserRouter>
      <Flex
        direction="column"
        minHeight="100vh"
        maxHeight="100vh"
        overflow="auto"
      >
         {/* <Header /> */}
        <LeftSideMenu />
        <Flex
          as="main"
          flex="1"
          mt="58px"
          direction="column"
          py="6"
          px="6"
          bgColor="white"
        >
          <Box>
            <Routes>
              <Route path="/" element={<HomeScreen />} />
              <Route path="/login" element={<LoginScreen />} />
              <Route path="/events" element={<EventListScreen />} />
              <Route path="/register" element={<RegisterScreen />} />
              <Route path="/profile" element={<ProfileScreen />} />
              <Route path="profile/:id" element={<ProfileScreen />} /> 
              <Route path="/members" element={<UserListScreen />} />
              <Route path="/dashboard/admin" element={<AdminScreen />} />
           
              {/* <Route path="/admin/user/:id/edit" element={<UserEditDrawer />} /> */}
              <Route
                path="/contributions"
                element={<ContributionListScreen />}
              />
              <Route path="/dashboard/members" element={<DashboardScreen />} />

              {/* New routes for Jenga API integration */}
              <Route path="/settings/integrations" element={<IntegrationsScreen />} />
        
              {/* <Route path="/settings/general" element={<GeneralSettings />} /> */}
              
              <Route path="/settings" element={<SettingsForm />} />
              
            </Routes>
            
          </Box>
        </Flex>
        <Footer />
      </Flex>
    </BrowserRouter>
  );
};
export default App;