import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Checkbox,
  Select,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { register, updateUser, listUsers } from "../actions/userActions";
import { USER_REGISTER_RESET, USER_UPDATE_RESET } from "../constants/userConstants";
import Message from "./Message";

const UserForm = ({ isOpen, onClose, user }) => {
  const dispatch = useDispatch();
  const toast = useToast();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState(null);
  const [phone, setPhone] = useState("");
  const [role, setRole] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);

  const userRegister = useSelector((state) => state.userRegister);
  const { loading: loadingRegister, error: errorRegister, success: successRegister } = userRegister;

  const userUpdate = useSelector((state) => state.userUpdate);
  const { loading: loadingUpdate, error: errorUpdate, success: successUpdate } = userUpdate;

  useEffect(() => {
    if (user) {
      setName(user.name);
      setEmail(user.email);
      setPhone(user.phone);
      setRole(user.role);
      setIsAdmin(user.isAdmin);
    } else {
      resetForm();
    }
  }, [user]);

  useEffect(() => {
    if (successRegister || successUpdate) {
      dispatch({ type: USER_REGISTER_RESET });
      dispatch({ type: USER_UPDATE_RESET });
      dispatch(listUsers());
      resetForm();
      onClose();
      toast({
        title: user ? "User Updated" : "User Created",
        description: user ? "User has been successfully updated." : "New user has been successfully created.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    }
  }, [dispatch, successRegister, successUpdate, onClose, user, toast]);

  const resetForm = () => {
    setName("");
    setEmail("");
    setPhone("");
    setRole("");
    setIsAdmin(false);
    setPassword("");
    setConfirmPassword("");
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (user) {
      // Updating existing user
      dispatch(updateUser({ 
        _id: user._id, 
        name, 
        email, 
        phone, 
        role, 
        isAdmin,
        ...(password && { password })
      }));
    } else {
      // Creating new user
      if (password !== confirmPassword) {
        toast({
          title: "Error",
          description: "Passwords do not match",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      } else {
        dispatch(register(name, email, phone, password, role, isAdmin));
      }
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{user ? 'Edit User' : 'Add New User'}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {errorRegister && <Message type="error">{errorRegister}</Message>}
          {errorUpdate && <Message type="error">{errorUpdate}</Message>}
          {message && <Message type="error">{message}</Message>}

          <FormControl id="name" mb="3">
            <FormLabel htmlFor="name">Name</FormLabel>
            <Input
              id="name"
              type="text"
              placeholder="Enter name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </FormControl>

          <FormControl id="email" mb="3">
            <FormLabel htmlFor="email">Email address</FormLabel>
            <Input
              id="email"
              type="email"
              placeholder="Enter email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </FormControl>

          <FormControl id="phone" mb="3">
            <FormLabel htmlFor="phone">Phone Number</FormLabel>
            <Input
              id="phone"
              type="text"
              placeholder="+254722111333"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
          </FormControl>

          <FormControl id="role" mb="3">
            <FormLabel htmlFor="role">Role</FormLabel>
            <Select
              id="role"
              value={role}
              onChange={(e) => setRole(e.target.value)}
            >
              <option value="">Select Role</option>
              <option value="member">Member</option>
              <option value="admin">Admin</option>
            </Select>
          </FormControl>

          {!user && (
            <>
              <FormControl id="password" mb="3">
                <FormLabel htmlFor="password">Password</FormLabel>
                <Input
                  id="password"
                  type="password"
                  placeholder="Enter password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </FormControl>

              <FormControl id="confirmPassword" mb="3">
                <FormLabel htmlFor="confirmPassword">Confirm Password</FormLabel>
                <Input
                  id="confirmPassword"
                  type="password"
                  placeholder="Confirm password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
              </FormControl>
            </>
          )}

          {user && (
            <FormControl id="password" mb="3">
              <FormLabel htmlFor="password">New Password (leave blank to keep current)</FormLabel>
              <Input
                id="password"
                type="password"
                placeholder="Enter new password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </FormControl>
          )}

          <FormControl id="isAdmin" mb="3">
            <Checkbox
              id="isAdmin"
              isChecked={isAdmin}
              onChange={(e) => setIsAdmin(e.target.checked)}
            >
              Is Super Admin
            </Checkbox>
          </FormControl>
        </ModalBody>

        <ModalFooter>
          <Button
            colorScheme="blue"
            mr={3}
            onClick={submitHandler}
            isLoading={loadingRegister || loadingUpdate}
          >
            {user ? 'Update' : 'Submit'}
          </Button>
          <Button variant="ghost" onClick={onClose}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default UserForm;