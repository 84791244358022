import {
  Box,
  Button,
  Flex,
  Heading,
  Icon,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
  } from "@chakra-ui/react";
  import { useEffect, useState  } from "react";
  import {
    IoCheckmarkCircleSharp,
    IoCloseCircleSharp,
    IoPencilSharp,
    IoTrashBinSharp,
  } from "react-icons/io5";
  import { useDispatch, useSelector } from "react-redux";
  import { Link as RouterLink, useNavigate } from "react-router-dom";
  import { listUsers, deleteUser } from "../actions/userActions";
  import Loader from "../components/Loader";
  import Message from "../components/Message";
  import UserForm from "../components/UserForm";
  
  const UserListScreen = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [editUser, setEditUser] = useState(null);

    const userRegister = useSelector((state) => state.userRegister);
    const { success: successRegister } = userRegister;
  
    const userList = useSelector((state) => state.userList);
    const { loading, error, users } = userList;
  
    const userLogin = useSelector((state) => state.userLogin);
    const { userInfo } = userLogin;
  
    const userDelete = useSelector((state) => state.userDelete);
    const { success } = userDelete;
  
    useEffect(() => {
      if (userInfo && userInfo.isAdmin) {
        dispatch(listUsers());
      } else {
        navigate("/login");
      }
    }, [dispatch, navigate, userInfo, successRegister, success]);
  
    const deleteHandler = (id) => {
      if (window.confirm("Are you sure?")) {
        if (userInfo && userInfo._id === id) {
          alert("You cannot delete yourself.");
        } else {
          const userToDelete = users.find((user) => user._id === id);
          if (userToDelete && userToDelete.role === "admin") {
            alert("You cannot delete admin users.");
          } else {
            alert("Are you Crazy! Deleting users is not allowed.");
          }
        }
      }
    };

    const editHandler = (user) => {
      setEditUser(user);
      onOpen();
    };
  
    return (
      <>
        <Box bgColor="white" rounded="lg" shadow="lg" px="5" py="5" mb="5">
          <Flex justifyContent="space-between" alignItems="center">
          <Heading as="h1" size="xl" color="teal" >
          Members
        </Heading>
        <Button colorScheme="teal" onClick={() => { setEditUser(null); onOpen(); }} minWidth="15">
              +
            </Button>
          </Flex>
        </Box>
  
        {loading ? (
          <Loader />
        ) : error ? (
          <Message type="error">{error}</Message>
        ) : (
          <Box bgColor="white" rounded="lg" shadow="lg" px="5" py="5">
            <Table variant="striped" colorScheme="gray" size="sm">
              <Thead>
                <Tr>
                  <Th>ID</Th>
                  <Th>NAME</Th>
                  <Th>EMAIL</Th>
                  <Th>PHONE</Th>
                  <Th>ROLE</Th>
                  <Th>SUPER ADMIN</Th>
                  <Th>ACTIONS</Th>
                </Tr>
              </Thead>
              <Tbody>
                {users.map((user) => (
                  <Tr key={user._id}>
                    <Td>{user._id}</Td>
                    <Td>{user.name}</Td>
                    <Td>
                      <a href={`mailto:${user.email}`}>{user.email}</a>
                    </Td>
                    <Td>{user.phone}</Td>
                    <Td>{user.role}</Td>
                    <Td>
                      {user.isAdmin ? (
                        <Icon
                          as={IoCheckmarkCircleSharp}
                          color="green.600"
                          w="8"
                          h="8"
                        />
                      ) : (
                        <Icon
                          as={IoCloseCircleSharp}
                          color="red.600"
                          w="8"
                          h="8"
                        />
                      )}
                    </Td>
                    <Td>
                    <Flex justifyContent="flex-end" alignItems="center">
                      <Button
                        mr="4"
                        colorScheme="teal"
                        onClick={() => editHandler(user)}
                      >
                        <Icon as={IoPencilSharp} color="white" size="sm" />
                      </Button>
                      <Button
                        mr="4"
                        colorScheme="red"
                        onClick={() => deleteHandler(user._id)}
                      >
                        <Icon as={IoTrashBinSharp} color="white" size="sm" />
                      </Button>
                    </Flex>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>
        )}
      <UserForm isOpen={isOpen} onClose={() => { onClose(); setEditUser(null); }} user={editUser} />
      </>
    );
  };
  
  export default UserListScreen;