import {
    Box,
    Button,
    Flex,
    Heading,
    Table,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    useDisclosure,
    Text,
  } from "@chakra-ui/react";
  
  import { useEffect } from "react";
  import { useDispatch, useSelector } from "react-redux";
  import { listContributions } from "../actions/contributionActions";
  import ContributionForm from "../components/ContributionForm";
  import Loader from "../components/Loader";
  import Message from "../components/Message";
  import {pdf} from "@react-pdf/renderer";
  import ContributionStatementPDF from "../components/ContributionStatementPDF"


  const ContributionListScreen = () => {
    const dispatch = useDispatch();
    const { isOpen, onOpen, onClose } = useDisclosure();
  
    const contributionList = useSelector((state) => state.contributionList);
    const { loading, error, contributions } = contributionList;

    const userLogin = useSelector((state) => state.userLogin);
    const { userInfo } = userLogin;
  
    useEffect(() => {
      dispatch(listContributions());
    }, [dispatch]);

    const handleDownloadPDF = async () => {
        const blob = await pdf(<ContributionStatementPDF contributions={contributions} user={userInfo} />).toBlob();
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = "contribution_statement.pdf";
        link.click();
      };
  
    return (
      <>
       <Box bgColor="white" rounded="lg" shadow="lg" px="5" py="5" mb="5">
        <Flex justifyContent="space-between" alignItems="center">
        <Heading as="h1" size="xl" color="teal" >
          Contributions
        </Heading>
          <Flex>
            <Button colorScheme="blue" onClick={handleDownloadPDF} mr="4">
              Download PDF
            </Button>
            <Button colorScheme="teal" onClick={onOpen} minWidth="15">
              +
            </Button>
          </Flex>
        </Flex>
      </Box>
  
        {loading ? (
          <Loader />
        ) : error ? (
          <Message type="error">{error}</Message>
        ) : (
          <Box bgColor="white" rounded="lg" shadow="lg" px="5" py="5">
            <Table variant="striped" colorScheme="gray" size="sm">
              <Thead>
                <Tr>
                  <Th>Event</Th>
                  <Th>Date</Th>
                  <Th>Member</Th>
                  <Th>Amount</Th>
                  <Th>Payment Method</Th>
                  <Th>Transaction ID</Th>
                  <Th>Mpesa Reference</Th>
                  <Th>Status</Th>
                  <Th>Actions</Th>
                  {/* <Th></Th> */}
                </Tr>
              </Thead>
              <Tbody>
                {contributions.map((contribution) => (
                  <Tr key={contribution._id}>
                    <Td>{contribution.event?.name || 'N/A'}</Td>
                    <Td>{new Date(contribution.createdAt).toLocaleDateString()}</Td>
                    <Td>{contribution.user?.name || 'N/A'}</Td>
                    <Td>{contribution.amount}</Td>
                    <Td>{contribution.paymentMethod}</Td>
                    <Td>{contribution.transactionId}</Td>
                    <Td>{contribution.telcoReference || 'N/A'}</Td>
                    <Td>{contribution.paymentStatus}</Td>
  
                    <Td>
                      <Flex justifyContent="flex-end" alignItems="center">
                        <Button
                          mr="4"
                          colorScheme="teal"
                          // onClick={() => handleView(contribution._id)}
                        >
                         &gt;
                        </Button>

                      </Flex> 
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
            {contributions.length === 0 && (
            <Text mt={4} textAlign="center">No contributions found.</Text>
          )}
          </Box>
        )}
        <ContributionForm isOpen={isOpen} onClose={onClose} />
      </>
    );
  };
  
  export default ContributionListScreen;