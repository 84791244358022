import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
  Text,
  useToast
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { createContribution } from "../actions/contributionActions";
import { listEvents } from "../actions/eventActions";
import { listUsers } from "../actions/userActions";
import { CONTRIBUTION_CREATE_RESET } from "../constants/contributionConstants";
import { listContributions } from "../actions/contributionActions";
import { initiateMpesaStk } from "../actions/mpesaActions"; // Assuming you have this action
import axios from "axios";

const ContributionForm = ({ isOpen, onClose }) => {
  const dispatch = useDispatch();
  const toast = useToast();

  const [event, setEvent] = useState("");
  const [user, setUser] = useState("");
  const [amount, setAmount] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("Mpesa");
  const [mpesaNumber, setMpesaNumber] = useState("");
  const [events, setEvents] = useState([]);
  const [users, setUsers] = useState([]);

  const contributionCreate = useSelector((state) => state.contributionCreate);
  const { loading, success, error } = contributionCreate;

  const eventList = useSelector((state) => state.eventList);
  const { events: eventsList } = eventList;

  const userList = useSelector((state) => state.userList);
  const { users: usersList } = userList;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    dispatch(listEvents());
    setUser(userInfo._id);
      setMpesaNumber(userInfo.phone); // Assuming phone number is stored in userInfo
      dispatch(listUsers());
  }, [dispatch, userInfo]);

  useEffect(() => {
    if (eventsList) {
      setEvents(eventsList);
    }
  }, [eventsList]);

  useEffect(() => {
    if (usersList) {
      setUsers(usersList);
    }
  }, [usersList]);

  useEffect(() => {
    if (!userInfo.isAdmin) {
      setUser(userInfo._id);
      setMpesaNumber(userInfo.phone); // Assuming phone number is stored in userInfo
    }
  }, [userInfo]);

  useEffect(() => {
    if (success) {
      dispatch({ type: CONTRIBUTION_CREATE_RESET });
      onClose();
      toast({
        title: "Contribution added successfully",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    }
  }, [success, dispatch, onClose, toast]);

  const handleEventChange = (e) => {
    const selectedEventId = e.target.value;
    setEvent(selectedEventId);
    const selectedEvent = events.find(evt => evt._id === selectedEventId);
    if (selectedEvent) {
      setAmount(selectedEvent.contributionAmount);
    } else {
      setAmount("");
    }
  };

  // const submitHandler = async (e) => {
  //   e.preventDefault();
  //   if (paymentMethod === "Mpesa") {
  //     try {
  //       const stkResponse = await dispatch(initiateMpesaStk(mpesaNumber, amount, event));
  //       console.log("Loggging Submit Details",{ mpesaNumber, amount, event });

  //       if (stkResponse.success) {
  //         dispatch(createContribution({ 
  //           event, 
  //           user, 
  //           amount, 
  //           paymentMethod, 
  //           transactionId: stkResponse.transactionId 
  //         }));
  //       }
  //     } catch (error) {
  //       toast({
  //         title: "Payment failed",
  //         description: error.message,
  //         status: "error",
  //         duration: 5000,
  //         isClosable: true,
  //       });
  //     }
  //   } else {
  //     dispatch(createContribution({ event, user, amount, paymentMethod }));
  //   }
  // };

  const submitHandler = async (e) => {
    e.preventDefault();
    if (paymentMethod === "Mpesa") {
      try {
        const stkResponse = await dispatch(initiateMpesaStk(mpesaNumber, amount, event));
        console.log("Logging Submit Details", { mpesaNumber, amount, event, reference: stkResponse.reference });
  
        if (stkResponse.success) {
          toast({
            title: "Payment initiated",
            description: "Please check your phone to complete the payment",
            status: "info",
            duration: 5000,
            isClosable: true,
          });

          // Start checking the transaction status
          checkTransactionStatus(stkResponse.transactionId);

          onClose(); // Close the modal after initiating payment
        } else {
          throw new Error(stkResponse.message || 'Failed to initiate payment');
        }
      } catch (error) {
        toast({
          title: "Payment initiation failed",
          description: error.message,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } else {
      dispatch(createContribution({ event, user, amount, paymentMethod }));
    }
  };

  const checkTransactionStatus = async (transactionId) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const response = await axios.get(`/api/mpesa/transaction-status/${transactionId}`, config);

      console.log('Transaction status response:', response.data); // Add this line for debugging

      
      if (response.data.status === 'completed') {
        toast({
          title: "Payment successful",
          description: `Mpesa Reference Code: ${response.data.telcoReference}`,
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        dispatch(listContributions()); // Refresh the list
      } else if (response.data.status === 'failed') {
        toast({
          title: "Payment failed",
          description: response.data.details.message,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      } else {
        // Payment still pending, check again after a delay
        setTimeout(() => checkTransactionStatus(transactionId), 5000);
      }
    } catch (error) {
      console.error('Error checking transaction status:', error);
      toast({
        title: "Error checking payment status",
        description: "Please try again or contact support",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add New Contribution</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl id="event" mb="3">
            <FormLabel>Event</FormLabel>
            <Select
              placeholder="Select event"
              value={event}
              onChange={handleEventChange}
            >
              {events.map((evt) => (
                <option key={evt._id} value={evt._id}>
                  {evt.name}
                </option>
              ))}
            </Select>
          </FormControl>
          {/* {userInfo.isAdmin && (
            <FormControl id="user" mb="3">
              <FormLabel>Member</FormLabel>
              <Select
                placeholder="Select member"
                value={user}
                onChange={(e) => setUser(e.target.value)}
              >
                {users.map((usr) => (
                  <option key={usr._id} value={usr._id}>
                    {usr.name}
                  </option>
                ))}
              </Select>
            </FormControl>
          )} */}
          <FormControl id="amount" mb="3">
            <FormLabel>Amount</FormLabel>
            <Input
              type="number"
              value={amount}
              isReadOnly
              bg="gray.100"
            />
            {amount && (
              <Text fontSize="sm" color="gray.600" mt={1}>
                This amount is set by the event and cannot be changed.
              </Text>
            )}
          </FormControl>
          <FormControl id="paymentMethod" mb="3">
            <FormLabel>Payment Method</FormLabel>
            <Input
              value={paymentMethod}
              isReadOnly
              bg="gray.100"
            />
          </FormControl>
          <FormControl id="mpesaNumber" mb="3">
            <FormLabel>Mpesa Payment Number</FormLabel>
            <Input
              value={mpesaNumber}
              onChange={(e) => setMpesaNumber(e.target.value)}
              placeholder="Enter Mpesa number"
            />
          </FormControl>
        </ModalBody>

        <ModalFooter>
          <Button
            colorScheme="blue"
            mr={3}
            onClick={submitHandler}
            isLoading={loading}
            isDisabled={!event || !user || !amount || !mpesaNumber}
          >
            Submit
          </Button>
          <Button variant="ghost" onClick={onClose}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ContributionForm;