import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Flex,
  Heading,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { listEvents, deleteEvent } from "../actions/eventActions";
import Loader from "../components/Loader";
import Message from "../components/Message";
import EventForm from "../components/EventForm";

const EventListScreen = () => {
  const dispatch = useDispatch();
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [editingEvent, setEditingEvent] = useState(null);

  const eventList = useSelector((state) => state.eventList);
  const { loading, error, events } = eventList;

  const eventDelete = useSelector((state) => state.eventDelete);
  const { success: successDelete, error: errorDelete } = eventDelete;

  useEffect(() => {
    dispatch(listEvents());
    if (successDelete) {
      toast({
        title: "Event deleted",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    }
  }, [dispatch, successDelete, toast]);

  const handleEdit = (event) => {
    setEditingEvent(event);
    onOpen();
  };

  const handleDelete = (eventId) => {
    if (window.confirm("Are you sure you want to delete this event?")) {
      dispatch(deleteEvent(eventId));
    }
  };

  return (
    <>
      <Box bgColor="white" rounded="lg" shadow="lg" px="5" py="5" mb="5">
        <Flex justifyContent="space-between" alignItems="center">
          <Heading as="h1" size="xl" color="teal">
            Events
          </Heading>
          <Button colorScheme="teal" onClick={() => { setEditingEvent(null); onOpen(); }} minWidth="15">
            +
          </Button>
        </Flex>
      </Box>

      {loading ? (
        <Loader />
      ) : error ? (
        <Message type="error">{error}</Message>
      ) : (
        <Box bgColor="white" rounded="lg" shadow="lg" px="5" py="5">
          <Table variant="striped" colorScheme="gray" size="sm">
            <Thead>
              <Tr>
                <Th>Name</Th>
                <Th>Description</Th>
                <Th>Date</Th>
                <Th>Location</Th>
                <Th>Created By</Th>
                <Th>Contributions</Th>
                
                <Th>Actions</Th>
              </Tr>
            </Thead>
            <Tbody>
              {events.map((event) => (
                <Tr key={event._id}>
                  <Td>{event.name}</Td>
                  <Td>{event.description}</Td>
                  <Td>{new Date(event.date).toLocaleDateString()}</Td>
                  <Td>{event.location}</Td>
                  <Td>{event.createdBy.name}</Td>
                  <Td>{event.contributionAmount}</Td> 
                  <Td>
                    <Flex justifyContent="flex-end" alignItems="center">
                      <Button
                        mr="4"
                        colorScheme="blue"
                        onClick={() => handleEdit(event)}
                      >
                        Edit
                      </Button>
                      <Button
                        colorScheme="red"
                        onClick={() => handleDelete(event._id)}
                        isDisabled={event.contributions && event.contributions.length > 0}
                      >
                        Delete
                      </Button>
                    </Flex>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
      )}
      <EventForm isOpen={isOpen} onClose={() => { onClose(); setEditingEvent(null); }} event={editingEvent} />
    </>
  );
};

export default EventListScreen;