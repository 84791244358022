import React, { useState, useEffect } from 'react';
import {
  Box,
  VStack,
  Heading,
  FormControl,
  FormLabel,
  Input,
  Button,
  Switch,
  Select,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  useToast,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Badge,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from '@chakra-ui/react';
import IntegrationTab from './IntegrationTab';
import { useDispatch, useSelector } from 'react-redux';
import { listUsers, updateUserRole } from '../actions/userActions'; 

const SettingsForm = () => {
  const [profileSettings, setProfileSettings] = useState({
    name: '',
    email: '',
    company: '',
  });

  const [notificationSettings, setNotificationSettings] = useState({
    emailNotifications: true,
    pushNotifications: false,
  });

  const [securitySettings, setSecuritySettings] = useState({
    twoFactorAuth: false,
  });

  const [billingSettings, setBillingSettings] = useState({
    plan: 'basic',
    cardNumber: '',
    expiryDate: '',
    cvv: '',
  });

  const toast = useToast();

  const handleProfileSubmit = (e) => {
    e.preventDefault();
    // Handle profile update logic here
    toast({
      title: 'Profile Updated',
      status: 'success',
      duration: 3000,
      isClosable: true,
    });
  };

  const handleNotificationSubmit = (e) => {
    e.preventDefault();
    // Handle notification settings update logic here
    toast({
      title: 'Notification Settings Updated',
      status: 'success',
      duration: 3000,
      isClosable: true,
    });
  };

  const handleSecuritySubmit = (e) => {
    e.preventDefault();
    // Handle security settings update logic here
    toast({
      title: 'Security Settings Updated',
      status: 'success',
      duration: 3000,
      isClosable: true,
    });
  };

  const handleBillingSubmit = (e) => {
    e.preventDefault();
    // Handle billing information update logic here
    toast({
      title: 'Billing Information Updated',
      status: 'success',
      duration: 3000,
      isClosable: true,
    });
  };

  const handleIntegrationSubmit = (e) => {
    e.preventDefault();
    // Handle integration settings update logic here
    toast({
      title: 'Integration Settings Updated',
      status: 'success',
      duration: 3000,
      isClosable: true,
    });
  };
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const dispatch = useDispatch();

  const userList = useSelector((state) => state.userList);
  const { loading: userLoading, error: userError, users: userListData } = userList;

  useEffect(() => {
    dispatch(listUsers());
  }, [dispatch]);

  useEffect(() => {
    if (userListData) {
      setUsers(userListData);
    }
  }, [userListData]);

  const handleRoleChange = (userId, newRole) => {
    dispatch(updateUserRole(userId, newRole));
    toast({
      title: "Role Updated",
      description: "User role has been successfully updated.",
      status: "success",
      duration: 3000,
      isClosable: true,
    });
  };

  const openUserModal = (user) => {
    setSelectedUser(user);
    onOpen();
  };
  return (
    <Box>
      <Heading as="h1" size="xl" mb={6} color="teal">
        Settings
      </Heading>
      <Tabs>
        <TabList>
          <Tab>Profile</Tab>
          <Tab>Notifications</Tab>
          <Tab>Security</Tab>
          <Tab>Billing</Tab>
          <Tab>Integrations</Tab>
          <Tab>User Management</Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <form onSubmit={handleProfileSubmit}>
              <VStack spacing={4} align="stretch">
                <FormControl>
                  <FormLabel>Name</FormLabel>
                  <Input
                    value={profileSettings.name}
                    onChange={(e) => setProfileSettings({ ...profileSettings, name: e.target.value })}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Email</FormLabel>
                  <Input
                    type="email"
                    value={profileSettings.email}
                    onChange={(e) => setProfileSettings({ ...profileSettings, email: e.target.value })}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Company</FormLabel>
                  <Input
                    value={profileSettings.company}
                    onChange={(e) => setProfileSettings({ ...profileSettings, company: e.target.value })}
                  />
                </FormControl>
                <Button type="submit" colorScheme="blue">
                  Update Profile
                </Button>
              </VStack>
            </form>
          </TabPanel>

          <TabPanel>
            <form onSubmit={handleNotificationSubmit}>
              <VStack spacing={4} align="stretch">
                <FormControl display="flex" alignItems="center">
                  <FormLabel htmlFor="email-notifications" mb="0">
                    Email Notifications
                  </FormLabel>
                  <Switch
                    id="email-notifications"
                    isChecked={notificationSettings.emailNotifications}
                    onChange={(e) => setNotificationSettings({ ...notificationSettings, emailNotifications: e.target.checked })}
                  />
                </FormControl>
                <FormControl display="flex" alignItems="center">
                  <FormLabel htmlFor="push-notifications" mb="0">
                    Push Notifications
                  </FormLabel>
                  <Switch
                    id="push-notifications"
                    isChecked={notificationSettings.pushNotifications}
                    onChange={(e) => setNotificationSettings({ ...notificationSettings, pushNotifications: e.target.checked })}
                  />
                </FormControl>
                <Button type="submit" colorScheme="blue">
                  Update Notification Settings
                </Button>
              </VStack>
            </form>
          </TabPanel>

          <TabPanel>
            <form onSubmit={handleSecuritySubmit}>
              <VStack spacing={4} align="stretch">
                <FormControl display="flex" alignItems="center">
                  <FormLabel htmlFor="two-factor-auth" mb="0">
                    Two-Factor Authentication
                  </FormLabel>
                  <Switch
                    id="two-factor-auth"
                    isChecked={securitySettings.twoFactorAuth}
                    onChange={(e) => setSecuritySettings({ ...securitySettings, twoFactorAuth: e.target.checked })}
                  />
                </FormControl>
                <Button type="submit" colorScheme="blue">
                  Update Security Settings
                </Button>
              </VStack>
            </form>
          </TabPanel>

          <TabPanel>
            <form onSubmit={handleBillingSubmit}>
              <VStack spacing={4} align="stretch">
                <FormControl>
                  <FormLabel>Subscription Plan</FormLabel>
                  <Select
                    value={billingSettings.plan}
                    onChange={(e) => setBillingSettings({ ...billingSettings, plan: e.target.value })}
                  >
                    <option value="basic">Basic</option>
                    <option value="pro">Pro</option>
                    <option value="enterprise">Enterprise</option>
                  </Select>
                </FormControl>
                <FormControl>
                  <FormLabel>Card Number</FormLabel>
                  <Input
                    type="text"
                    value={billingSettings.cardNumber}
                    onChange={(e) => setBillingSettings({ ...billingSettings, cardNumber: e.target.value })}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Expiry Date</FormLabel>
                  <Input
                    type="text"
                    value={billingSettings.expiryDate}
                    onChange={(e) => setBillingSettings({ ...billingSettings, expiryDate: e.target.value })}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>CVV</FormLabel>
                  <Input
                    type="text"
                    value={billingSettings.cvv}
                    onChange={(e) => setBillingSettings({ ...billingSettings, cvv: e.target.value })}
                  />
                </FormControl>
                <Button type="submit" colorScheme="blue">
                  Update Billing Information
                </Button>
              </VStack>
            </form>
          </TabPanel>
          <TabPanel>
  <IntegrationTab />
</TabPanel>
<TabPanel>
            <Heading as="h2" size="lg" mb={4} color="teal">User Management</Heading>
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th>Name</Th>
                  <Th>Email</Th>
                  <Th>Role</Th>
                  <Th>Actions</Th>
                </Tr>
              </Thead>
              <Tbody>
                {users.map((user) => (
                  <Tr key={user._id}>
                    <Td>{user.name}</Td>
                    <Td>{user.email}</Td>
                    <Td>
                      <Select
                        value={user.role}
                        onChange={(e) => handleRoleChange(user._id, e.target.value)}
                      >
                        <option value="user">User</option>
                        <option value="admin">Admin</option>
                        <option value="superadmin">Super Admin</option>
                      </Select>
                    </Td>
                    <Td>
                      <Button onClick={() => openUserModal(user)}>Manage Permissions</Button>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TabPanel>
        </TabPanels>
      </Tabs>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Manage Permissions for {selectedUser?.name}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack align="stretch">
              {/* Add permission toggles here */}
              <FormControl display="flex" alignItems="center">
                <FormLabel htmlFor="create-events" mb="0">
                  Create Events
                </FormLabel>
                <Switch id="create-events" />
              </FormControl>
              <FormControl display="flex" alignItems="center">
                <FormLabel htmlFor="manage-users" mb="0">
                  Manage Users
                </FormLabel>
                <Switch id="manage-users" />
              </FormControl>
              {/* Add more permissions as needed */}
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Save
            </Button>
            <Button variant="ghost" onClick={onClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default SettingsForm;