import { extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
  fonts: {
    heading: 'Montserrat, sans-serif' ,
    body: 'Montserrat, sans-serif',
  },
  fontWeights: {
    normal: 300,
    medium: 500,
    bold: 900,
  },
});

export default theme;