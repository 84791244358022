import React from 'react';
import {
    Box,
    Flex,
    VStack,
    Heading,
    Icon,
    Link,
    Button,
    Drawer,
    DrawerBody,
    DrawerHeader,
    DrawerFooter,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    useDisclosure,
    Avatar,
    Text,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    useColorModeValue,
} from "@chakra-ui/react";
import { Link as RouterLink, useNavigate, useLocation } from "react-router-dom";
import { HiMenu, HiX, HiInformationCircle, HiChartBar, HiCash, HiCalendar, HiUserGroup, HiCog } from "react-icons/hi";
import { IoChevronDown } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../actions/userActions";

const LeftSideMenu = () => {
  const { isOpen, onOpen, onClose, onToggle } = useDisclosure();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const logoutHandler = () => {
    dispatch(logout());
    navigate("/login");
    onClose();
  };

  const menuItems = [
    { label: "Admin Dashboard", url: "/dashboard/admin", icon: HiChartBar },
    { label: "My Dashboard", url: "/dashboard/members", icon: HiChartBar },
    { label: "Contributions", url: "/contributions", icon: HiCash },
    { label: "Events", url: "/events", icon: HiCalendar },
    { label: "Members", url: "/members", icon: HiUserGroup },
  ];

  const settingsItems = [
    { label: "Settings", url: "/settings", icon: HiCog },
  ];

  const bgColor = useColorModeValue("teal.500", "teal.700");
  const hoverBgColor = useColorModeValue("teal.600", "teal.600");
  const activeMenuItemColor = useColorModeValue("yellow.300", "yellow.200");

  const MenuLink = ({ item }) => (
    <Link
      as={RouterLink}
      to={item.url}
      fontSize="xl"
      fontWeight="bold"
      p="2"
      borderRadius="md"
      _hover={{ 
        textDecoration: "none", 
        bg: hoverBgColor, 
        transform: "translateX(5px)",
        transition: "all 0.3s ease"
      }}
      _active={{ bg: hoverBgColor }}
      bg={location.pathname === item.url ? hoverBgColor : "transparent"}
      color={location.pathname === item.url ? activeMenuItemColor : "white"}
      onClick={onClose}
      display="flex"
      alignItems="center"
    >
      <Icon as={item.icon} mr="3" />
      {item.label}
    </Link>
  );

  return (
    <Box>
      <Box
        as="header"
        pos="fixed"
        top="0"
        left="0"
        right="0"
        bg={bgColor}
        color="white"
        p="4"
        zIndex="99999"
        boxShadow="md"
      >
        {/* Highlight start */}
        <Flex alignItems="center" justifyContent="space-between">
          <Flex alignItems="center">
        {/* Highlight end */}
            {userInfo && (
              <Button
                onClick={onToggle}
                variant="ghost"
                color="white"
                _hover={{ bg: hoverBgColor }}
                mr="4"
                aria-label={isOpen ? "Close menu" : "Open menu"}
              >
                <Icon as={isOpen ? HiX : HiMenu} w="6" h="6" />
              </Button>
            )}
            <Link
              as={RouterLink}
              to="/"
              _hover={{ textDecoration: "none", color: "white" }}
              display="flex"
              alignItems="center"
            >
              <Icon as={HiChartBar} w="8" h="8" mr="2" />
              <Heading as="h1" size="lg" fontWeight="bold">
                Weza Chama
              </Heading>
            </Link>
          {/* Highlight start */}
          </Flex>
          {!userInfo && (
            <Button
              as={RouterLink}
              to="/login"
              colorScheme="teal"
              variant="outline"
              _hover={{ bg: "white" }}
            >
              Login
            </Button>
          )}
        </Flex>
        {/* Highlight end */}
      </Box>

      {userInfo && (
        <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
          <DrawerOverlay />
          <DrawerContent bg={bgColor} color="white">
            <DrawerCloseButton />
            <DrawerHeader borderBottomWidth="1px">Menu</DrawerHeader>
            <DrawerBody>
              <VStack spacing="4" align="stretch">
                {menuItems.map((item) => (
                  <MenuLink key={item.label} item={item} />
                ))}
              </VStack>
            </DrawerBody>
            <DrawerFooter borderTopWidth="1px">
              <VStack spacing="4" align="stretch" width="100%">
                {settingsItems.map((item) => (
                  <MenuLink key={item.label} item={item} />
                ))}
                <Menu>
                  <MenuButton
                    as={Button}
                    rightIcon={<IoChevronDown />}
                    bg={hoverBgColor}
                    _hover={{ bg: "teal.700" }}
                    _active={{ bg: "teal.800" }}
                    width="100%"
                  >
                    <Flex alignItems="center">
                      <Avatar size="sm" name={userInfo.name} src={userInfo.avatar} mr="2" />
                      <Text>{userInfo.name}</Text>
                    </Flex>
                  </MenuButton>
                  <MenuList bg="white" color="gray.800">
                    <MenuItem as={RouterLink} to="/profile" _hover={{ bg: "gray.100" }}>
                      Profile
                    </MenuItem>
                    <MenuItem onClick={logoutHandler} _hover={{ bg: "gray.100" }}>
                      Logout
                    </MenuItem>
                  </MenuList>
                </Menu>
              </VStack>
            </DrawerFooter>
          </DrawerContent>
        </Drawer>
      )}
    </Box>
  );
};

export default LeftSideMenu;