import React, { useState } from 'react';
import {
  Box,
  VStack,
  Heading,
  FormControl,
  FormLabel,
  Input,
  Button,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  useToast,
  Text,
} from '@chakra-ui/react';

const IntegrationTab = () => {
  const [bankIntegration, setBankIntegration] = useState({
    merchantCode: '',
    consumerSecret: '',
    apiKey: '',
  });

  const [mpesaIntegration, setMpesaIntegration] = useState({
    shortCode: '',
    consumerKey: '',
    consumerSecret: '',
  });

  const toast = useToast();

  const handleBankIntegrationSubmit = (e) => {
    e.preventDefault();
    // Handle bank integration update logic here
    console.log('Bank Integration Settings:', bankIntegration);
    toast({
      title: 'Bank Integration Settings Updated',
      status: 'success',
      duration: 3000,
      isClosable: true,
    });
  };

  const handleMpesaIntegrationSubmit = (e) => {
    e.preventDefault();
    // Handle Mpesa integration update logic here
    console.log('Mpesa Integration Settings:', mpesaIntegration);
    toast({
      title: 'Mpesa Integration Settings Updated',
      status: 'success',
      duration: 3000,
      isClosable: true,
    });
  };

  return (
    <Box>
      <Heading as="h2" size="lg" mb={6} color="teal">
        Integration Settings
      </Heading>
      <Tabs>
        <TabList>
          <Tab>Bank Integration</Tab>
          <Tab>Mpesa Integration</Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <form onSubmit={handleBankIntegrationSubmit}>
              <VStack spacing={4} align="stretch">
                <Text>Configure your Jenga API settings for bank integration:</Text>
                <FormControl isRequired>
                  <FormLabel>Merchant Code</FormLabel>
                  <Input
                    value={bankIntegration.merchantCode}
                    onChange={(e) => setBankIntegration({ ...bankIntegration, merchantCode: e.target.value })}
                    placeholder="Enter your Merchant Code"
                  />
                </FormControl>
                <FormControl isRequired>
                  <FormLabel>Consumer Secret</FormLabel>
                  <Input
                    type="password"
                    value={bankIntegration.consumerSecret}
                    onChange={(e) => setBankIntegration({ ...bankIntegration, consumerSecret: e.target.value })}
                    placeholder="Enter your Consumer Secret"
                  />
                </FormControl>
                <FormControl isRequired>
                  <FormLabel>API Key</FormLabel>
                  <Input
                    type="password"
                    value={bankIntegration.apiKey}
                    onChange={(e) => setBankIntegration({ ...bankIntegration, apiKey: e.target.value })}
                    placeholder="Enter your API Key"
                  />
                </FormControl>
                <Button type="submit" colorScheme="blue">
                  Update Bank Integration Settings
                </Button>
              </VStack>
            </form>
          </TabPanel>

          <TabPanel>
            <form onSubmit={handleMpesaIntegrationSubmit}>
              <VStack spacing={4} align="stretch">
                <Text>Configure your Mpesa API settings for mobile payments:</Text>
                <FormControl isRequired>
                  <FormLabel>Short Code</FormLabel>
                  <Input
                    value={mpesaIntegration.shortCode}
                    onChange={(e) => setMpesaIntegration({ ...mpesaIntegration, shortCode: e.target.value })}
                    placeholder="Enter your Mpesa Short Code"
                  />
                </FormControl>
                <FormControl isRequired>
                  <FormLabel>Consumer Key</FormLabel>
                  <Input
                    type="password"
                    value={mpesaIntegration.consumerKey}
                    onChange={(e) => setMpesaIntegration({ ...mpesaIntegration, consumerKey: e.target.value })}
                    placeholder="Enter your Mpesa Consumer Key"
                  />
                </FormControl>
                <FormControl isRequired>
                  <FormLabel>Consumer Secret</FormLabel>
                  <Input
                    type="password"
                    value={mpesaIntegration.consumerSecret}
                    onChange={(e) => setMpesaIntegration({ ...mpesaIntegration, consumerSecret: e.target.value })}
                    placeholder="Enter your Mpesa Consumer Secret"
                  />
                </FormControl>
                <Button type="submit" colorScheme="green">
                  Update Mpesa Integration Settings
                </Button>
              </VStack>
            </form>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default IntegrationTab;