import axios from 'axios';
import {
  JENGA_API_CONFIG_CREATE_REQUEST,
  JENGA_API_CONFIG_CREATE_SUCCESS,
  JENGA_API_CONFIG_CREATE_FAIL,
  JENGA_API_CONFIG_GET_REQUEST,
  JENGA_API_CONFIG_GET_SUCCESS,
  JENGA_API_CONFIG_GET_FAIL,
  JENGA_API_CONFIG_UPDATE_REQUEST,
  JENGA_API_CONFIG_UPDATE_SUCCESS,
  JENGA_API_CONFIG_UPDATE_FAIL,
} from '../constants/jengaApiConstants';

export const createJengaApiConfig = (configData) => async (dispatch, getState) => {
    try {
      dispatch({ type: JENGA_API_CONFIG_CREATE_REQUEST });
  
      const { userLogin: { userInfo } } = getState();
  
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
  
      const { data } = await axios.post('/api/jenga', configData, config);
  
      dispatch({ type: JENGA_API_CONFIG_CREATE_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: JENGA_API_CONFIG_CREATE_FAIL,
        payload: error.response && error.response.data.message ? error.response.data.message : error.message,
      });
    }
  };

export const getJengaApiConfig = () => async (dispatch, getState) => {
  try {
    dispatch({ type: JENGA_API_CONFIG_GET_REQUEST });

    const { userLogin: { userInfo } } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get('/api/jenga', config);

    dispatch({ type: JENGA_API_CONFIG_GET_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: JENGA_API_CONFIG_GET_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    });
  }
};

export const updateJengaApiConfig = (configData) => async (dispatch, getState) => {
    try {
      dispatch({ type: JENGA_API_CONFIG_UPDATE_REQUEST });
  
      const { userLogin: { userInfo } } = getState();
  
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
  
      const { data } = await axios.put('/api/jenga', configData, config);
  
      dispatch({ type: JENGA_API_CONFIG_UPDATE_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: JENGA_API_CONFIG_UPDATE_FAIL,
        payload: error.response && error.response.data.message ? error.response.data.message : error.message,
      });
    }
  };