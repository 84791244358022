import axios from "axios";

import { CONTRIBUTION_CREATE_REQUEST, CONTRIBUTION_CREATE_SUCCESS, CONTRIBUTION_CREATE_FAIL, CONTRIBUTION_LIST_REQUEST, CONTRIBUTION_LIST_SUCCESS, CONTRIBUTION_LIST_FAIL, CONTRIBUTION_DELETE_REQUEST, CONTRIBUTION_DELETE_SUCCESS, CONTRIBUTION_DELETE_FAIL, CONTRIBUTION_UPDATE_REQUEST, CONTRIBUTION_UPDATE_SUCCESS, CONTRIBUTION_UPDATE_FAIL } from "../constants/contributionConstants";  

export const createContribution = (contribution) => async (dispatch, getState) => {
    try {
      dispatch({ type: CONTRIBUTION_CREATE_REQUEST });
  
      const {
        userLogin: { userInfo },
      } = getState();
  
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
  
      const { data } = await axios.post('/api/contributions', contribution, config);
  
      dispatch({ type: CONTRIBUTION_CREATE_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: CONTRIBUTION_CREATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

  export const listContributions = () => async (dispatch, getState) => {
    try {
      dispatch({ type: CONTRIBUTION_LIST_REQUEST });
  
      const {
        userLogin: { userInfo },
      } = getState();
  
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
  
      const { data } = await axios.get('/api/contributions', config);
  
      dispatch({ type: CONTRIBUTION_LIST_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: CONTRIBUTION_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

    export const deleteContribution = (id) => async (dispatch, getState) => {
        try {
        dispatch({ type: CONTRIBUTION_DELETE_REQUEST });
    
        const {
            userLogin: { userInfo },
        } = getState();
    
        const config = {
            headers: {
            Authorization: `Bearer ${userInfo.token}`,
            },
        };
    
        await axios.delete(`/api/contributions/${id}`, config);
    
        dispatch({ type: CONTRIBUTION_DELETE_SUCCESS });
        } catch (error) {
        dispatch({
            type: CONTRIBUTION_DELETE_FAIL,
            payload:
            error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
        });
        }
    };

    export const updateContribution = (contribution) => async (dispatch, getState) => {
        try {
        dispatch({ type: CONTRIBUTION_UPDATE_REQUEST });
    
        const {
            userLogin: { userInfo },
        } = getState();
    
        const config = {
            headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userInfo.token}`,
            },
        };
    
        const { data } = await axios.put(`/api/contributions/${contribution._id}`, contribution, config);
    
        dispatch({ type: CONTRIBUTION_UPDATE_SUCCESS, payload: data });
        } catch (error) {
        dispatch({
            type: CONTRIBUTION_UPDATE_FAIL,
            payload:
            error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
        });
        }
    };

