import axios from 'axios';
import {
  MPESA_STK_REQUEST,
  MPESA_STK_SUCCESS,
  MPESA_STK_FAIL
} from '../constants/mpesaConstants';

export const initiateMpesaStk = (mobileNumber, amount, eventId) => async (dispatch, getState) => {
  try {
    dispatch({
      type: MPESA_STK_REQUEST
    });

    const { userLogin: { userInfo } } = getState();

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`
      }
    };

    const { data } = await axios.post(
      '/api/mpesa/stk-push',
      {
        mobileNumber,
        amount: parseFloat(amount),
        reference: `EV${eventId.substring(0, 4)}${Date.now().toString().slice(-6)}`,
        event: eventId,
        user: userInfo._id
      },
      config
    );


    dispatch({
      type: MPESA_STK_SUCCESS,
      payload: data
    });

    return {...data, eventId};

  } catch (error) {
    dispatch({
      type: MPESA_STK_FAIL,
      payload: error.response && error.response.data.message
        ? error.response.data.message
        : error.message,
    });
    throw error;
  }
};