import React, { useState, useEffect } from "react";
import {
  Box,
  Flex,
  Heading,
  Icon,
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  StatArrow,
  Select,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  useDisclosure,
  VStack,
  Text,
  SimpleGrid,
  useBreakpointValue,
  Badge,
} from "@chakra-ui/react";
import { FaMoneyBillWave, FaMoneyCheck, FaBalanceScale } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { listContributions } from "../actions/contributionActions";
import ContributionForm from "../components/ContributionForm";
import Loader from "../components/Loader";
import Message from "../components/Message";

const TransactionDashboard = () => {
  const dispatch = useDispatch();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [dateFilter, setDateFilter] = useState("thisMonth");

  const contributionList = useSelector((state) => state.contributionList);
  const { loading, error, contributions } = contributionList;

  useEffect(() => {
    dispatch(listContributions());
  }, [dispatch]);

  const handleDateFilterChange = (event) => {
    setDateFilter(event.target.value);
  };

  // Calculate summary values based on the selected date filter
  const calculateSummary = () => {
    // Implement your logic to calculate moneyIn, moneyOut, and balance based on the selected date filter
    // You can use the contributions data and perform calculations accordingly
    // Return an object with the calculated values
    return {
      moneyIn: 45000,
      moneyOut: 20000,
      balance: 25000,
    };
  };

  const { moneyIn, moneyOut, balance } = calculateSummary();
  const isDesktop = useBreakpointValue({ base: false, md: true });

  return (
    <Box bgColor="white" rounded="lg" shadow="lg" p={5} mb={5}>
      <Flex direction={{ base: "column", md: "row" }} justifyContent="space-between" alignItems={{ base: "stretch", md: "center" }} mb={6}>
        <Heading as="h1" size="xl" color="teal" mb={{ base: 4, md: 0 }}>
          My Dashboard
        </Heading>
        <Select 
          value={dateFilter} 
          onChange={handleDateFilterChange} 
          width={{ base: "100%", md: "auto" }}
        >
          <option value="thisMonth">This Month</option>
          <option value="lastMonth">Last Month</option>
          <option value="thisYear">This Year</option>
          <option value="lastYear">Last Year</option>
        </Select>
      </Flex>

      <SimpleGrid columns={{ base: 1, md: 3 }} spacing={4} mb={8}>
        <StatCard icon={FaMoneyBillWave} label="Money In" value={moneyIn} color="green.500" change={23.36} increase={true} />
        <StatCard icon={FaMoneyCheck} label="Money Out" value={moneyOut} color="red.500" change={9.05} increase={false} />
        <StatCard icon={FaBalanceScale} label="Balance" value={balance} color="blue.500" />
      </SimpleGrid>

      <Heading as="h2" size="lg" color="teal" mb={4}>
        Recent Transactions
      </Heading>

      {loading ? (
        <Loader />
      ) : error ? (
        <Message type="error">{error}</Message>
      ) : (
        <Box overflowX="auto">
          {isDesktop ? (
            <Table variant="striped" colorScheme="gray" size="sm">
              <Thead>
                <Tr>
                  <Th>Date</Th>
                  <Th>Event</Th>
                  <Th>Member</Th>
                  <Th>Amount</Th>
                  <Th>Payment Method</Th>
                  <Th>Transaction ID</Th>
                  <Th>Mpesa Reference</Th>
                  <Th>Status</Th>
                </Tr>
              </Thead>
              <Tbody>
                {contributions.map((contribution) => (
                  <Tr key={contribution._id}>
                    <Td>{new Date(contribution.createdAt).toLocaleDateString()}</Td>
                    <Td>{contribution.event?.name || 'N/A'}</Td>
                    <Td>{contribution.user?.name || 'N/A'}</Td>
                    <Td>{contribution.amount}</Td>
                    <Td>{contribution.paymentMethod}</Td>
                    <Td>{contribution.transactionId}</Td>
                    <Td>{contribution.telcoReference || 'N/A'}</Td>
                    <Td>{contribution.paymentStatus}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          ) : (
            <VStack spacing={4} align="stretch">
              {contributions.map((contribution) => (
                <Box key={contribution._id} p={4} borderWidth={1} borderRadius="md" shadow="sm">
                  <Text><strong>Date:</strong> {new Date(contribution.createdAt).toLocaleDateString()}</Text>
                  <Text><strong>Event:</strong> {contribution.event?.name || 'N/A'}</Text>
                  <Text><strong>Member:</strong>{contribution.user?.name || 'N/A'}</Text>
                  <Text><strong>Amount:</strong> {contribution.amount}</Text>
                  <Text><strong>Payment Method:</strong> {contribution.paymentMethod}</Text>
                  <Text><strong>Transaction ID:</strong> {contribution.transactionId}</Text>
                  <Badge colorScheme={contribution.status === 'Paid' ? 'green' : 'red'}>
                    {contribution.status}
                  </Badge>
                </Box>
              ))}
            </VStack>
          )}
        </Box>
      )}
      <ContributionForm isOpen={isOpen} onClose={onClose} />
    </Box>
  );
}

const StatCard = ({ icon, label, value, color, change, increase }) => (
  <Box
    bg="white"
    borderRadius="lg"
    boxShadow="md"
    p={4}
    textAlign="center"
    borderWidth="2px"
    borderColor="gray.200"
  >
    <Icon as={icon} boxSize={8} color={color} mb={2} />
    <Stat>
      <StatLabel>{label}</StatLabel>
      <StatNumber>Kshs {value}</StatNumber>
      {change && (
        <StatHelpText>
          <StatArrow type={increase ? "increase" : "decrease"} />
          {change}%
        </StatHelpText>
      )}
    </Stat>
  </Box>
);

export default TransactionDashboard;