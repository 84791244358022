import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  NumberInput,
  NumberInputField,
  useToast,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { createEvent, updateEvent, listEvents } from "../actions/eventActions";
import { EVENT_CREATE_RESET, EVENT_UPDATE_RESET } from "../constants/eventConstants";

const EventForm = ({ isOpen, onClose, event }) => {
  const dispatch = useDispatch();
  const toast = useToast();

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [date, setDate] = useState("");
  const [location, setLocation] = useState("");
  const [contributionAmount, setContributionAmount] = useState("");

  const eventCreate = useSelector((state) => state.eventCreate);
  const { loading: loadingCreate, success: successCreate, error: errorCreate } = eventCreate;

  const eventUpdate = useSelector((state) => state.eventUpdate);
  const { loading: loadingUpdate, success: successUpdate, error: errorUpdate } = eventUpdate;

  useEffect(() => {
    if (event) {
      setName(event.name);
      setDescription(event.description);
      setDate(event.date.substring(0, 10)); // Format date to YYYY-MM-DD
      setLocation(event.location);
      setContributionAmount(event.contributionAmount);
    } else {
      resetForm();
    }
  }, [event]);

  useEffect(() => {
    if (successCreate || successUpdate) {
      dispatch({ type: successCreate ? EVENT_CREATE_RESET : EVENT_UPDATE_RESET });
      dispatch(listEvents());
      onClose();
      toast({
        title: event ? "Event Updated" : "Event Created",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    }
    if (errorCreate || errorUpdate) {
      toast({
        title: "Error",
        description: errorCreate || errorUpdate,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  }, [successCreate, successUpdate, errorCreate, errorUpdate, dispatch, onClose, toast, event]);

  const resetForm = () => {
    setName("");
    setDescription("");
    setDate("");
    setLocation("");
    setContributionAmount("");
  };

  const submitHandler = (e) => {
    e.preventDefault();
    const eventData = { name, description, date, location, contributionAmount };
    if (event) {
      dispatch(updateEvent({ ...eventData, _id: event._id }));
    } else {
      dispatch(createEvent(eventData));
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{event ? 'Edit Event' : 'Add New Event'}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl id="name" mb="3">
            <FormLabel>Name</FormLabel>
            <Input value={name} onChange={(e) => setName(e.target.value)} />
          </FormControl>
          <FormControl id="description" mb="3">
            <FormLabel>Description</FormLabel>
            <Textarea value={description} onChange={(e) => setDescription(e.target.value)} />
          </FormControl>
          <FormControl id="date" mb="3">
            <FormLabel>Date</FormLabel>
            <Input type="date" value={date} onChange={(e) => setDate(e.target.value)} />
          </FormControl>
          <FormControl id="location" mb="3">
            <FormLabel>Location</FormLabel>
            <Input value={location} onChange={(e) => setLocation(e.target.value)} />
          </FormControl>
          <FormControl id="contributionAmount" mb="3">
            <FormLabel>Contribution Amount per Member</FormLabel>
            <NumberInput min={0}> <NumberInputField value={contributionAmount} onChange={(e) => setContributionAmount(e.target.value)}/>
            </NumberInput>
          </FormControl>
        </ModalBody>

        <ModalFooter>
          <Button 
            colorScheme="blue" 
            mr={3} 
            onClick={submitHandler} 
            isLoading={loadingCreate || loadingUpdate}
          >
            {event ? 'Update' : 'Submit'}
          </Button>
          <Button variant="ghost" onClick={onClose}>Cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default EventForm;