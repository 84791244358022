import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Font,
} from "@react-pdf/renderer";

import RobotoRegular from "../fonts/Roboto/Roboto-Regular.ttf";
import RobotoBold from "../fonts/Roboto/Roboto-Bold.ttf";

Font.register({
  family: "Roboto",
  fonts: [
    { src: RobotoRegular, fontWeight: "light" },
    { src: RobotoBold, fontWeight: "bold" },
  ],
});

const styles = StyleSheet.create({
  page: {
    fontFamily: "Roboto",
    fontSize: 9,
    padding: 30,
    marginTop: 20,
  },
  section: {
    marginBottom: 20,
  },
  heading: {
    fontSize: 13,
    fontWeight: "bold",
    marginBottom: 10,
  },
  heading2: {
    fontSize: 13,
    marginBottom: 10,
  },
  summaryTable: {
    marginBottom: 20,
  },
  summaryTableHeader: {
    flexDirection: "row",
    borderBottomWidth: 1,
    borderBottomColor: "#000",
    borderBottomStyle: "solid",
    marginBottom: 5,
  },
  summaryTableRow: {
    flexDirection: "row",
  },
  summaryTableCell: {
    padding: 5,
    flex: 1,
  },
  detailedStatementTable: {
    borderWidth: 1,
    borderColor: "#000",
    borderStyle: "solid",
  },
  detailedStatementHeader: {
    flexDirection: "row",
    backgroundColor: "#f0f0f0",
    borderBottomWidth: 1,
    borderBottomColor: "#000",
    borderBottomStyle: "solid",
  },
  detailedStatementRow: {
    flexDirection: "row",
    borderBottomWidth: 1,
    borderBottomColor: "#000",
    borderBottomStyle: "solid",
  },
  detailedStatementCell: {
    padding: 5,
    flex: 1,
  },
});

const ContributionStatementPDF = ({ user, contributions }) => {
  console.log("User", user);
  const currentDate = new Date().toLocaleDateString();
  const statementPeriod = "01/01/2024 - 06/30/2024"; // Example period

  const moneyInEventType = "Monthly Contributions";

  const filterContributions = (type) => {
    return contributions.filter((contribution) => contribution.event && contribution.event.type === type);
  };

  const calculateTotal = (contributions, type, negateCondition = false) => {
    return contributions
      .filter((contribution) => contribution.event && (negateCondition ? contribution.event.type !== type : contribution.event.type === type))
      .reduce((total, contribution) => total + contribution.amount, 0);
  };

  const moneyIn = calculateTotal(contributions, moneyInEventType);
  const moneyOut = calculateTotal(contributions, moneyInEventType, true);

  return (
    <Document>
      <Page style={styles.page}>
        <View style={styles.header}>
          <View>
            <Text style={styles.heading2}>Membership Number: {''}</Text>
            <Text style={styles.heading2}>Member Name: {user.name}</Text>
            <Text style={styles.heading2}>Member ID: {user._id}</Text>
            <Text style={styles.heading2}>Date of Statement: {currentDate}</Text>
            <Text style={styles.heading2}>Period of Statement: {statementPeriod}</Text>
          </View>
        </View>
        <View style={styles.summaryTable}>
          <Text style={styles.heading}>Summary Statement</Text>
          <View style={styles.summaryTableHeader}>
            <Text style={styles.summaryTableCell}>Transaction Type</Text>
            <Text style={styles.summaryTableCell}>Money In</Text>
            <Text style={styles.summaryTableCell}>Money Out</Text>
          </View>
          <View style={styles.summaryTableRow}>
            <Text style={styles.summaryTableCell}>Monthly Contribution</Text>
            <Text style={styles.summaryTableCell}>Kshs{moneyIn.toFixed(2)}</Text>
            <Text style={styles.summaryTableCell}>Kshs{moneyOut.toFixed(2)}</Text>
          </View>
        </View>
        <View style={styles.section}>
          <Text style={styles.heading}>Detailed Statement</Text>
          <View style={styles.detailedStatementTable}>
            <View style={styles.detailedStatementHeader}>
              <Text style={styles.detailedStatementCell}>Transaction ID</Text>
              <Text style={styles.detailedStatementCell}>Date</Text>
              <Text style={styles.detailedStatementCell}>Event Type</Text>
              <Text style={styles.detailedStatementCell}>Status</Text>
              <Text style={styles.detailedStatementCell}>Money In</Text>
              <Text style={styles.detailedStatementCell}>Money Out</Text>
              <Text style={styles.detailedStatementCell}>Balance</Text>
            </View>
            {contributions.map((contribution, index) => {
              const moneyIn = contribution.event && contribution.event.type === moneyInEventType ? contribution.amount : 0;
              const moneyOut = contribution.event && contribution.event.type !== moneyInEventType ? contribution.amount : 0;
              const balance = contributions
                .slice(0, index + 1)
                .reduce((total, c) => total + (c.event && c.event.type === moneyInEventType ? c.amount : -c.amount), 0);

              return (
                <View key={contribution._id} style={styles.detailedStatementRow}>
                  <Text style={styles.detailedStatementCell}>{contribution.transactionId}</Text>
                  <Text style={styles.detailedStatementCell}>{contribution.createdAt ? new Date(contribution.createdAt).toLocaleDateString() : ''}</Text>
                  <Text style={styles.detailedStatementCell}>{contribution.event ? contribution.event.type : ''}</Text>
                  <Text style={styles.detailedStatementCell}>{contribution.status}</Text>
                  <Text style={styles.detailedStatementCell}>Kshs{moneyIn.toFixed(2)}</Text>
                  <Text style={styles.detailedStatementCell}>Kshs{moneyOut.toFixed(2)}</Text>
                  <Text style={styles.detailedStatementCell}>Kshs{balance.toFixed(2)}</Text>
                </View>
              );
            })}
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default ContributionStatementPDF;