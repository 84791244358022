import { applyMiddleware, combineReducers, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import {userRegisterReducer, userListReducer, userDeleteReducer, userUpdateReducer, userLoginReducer, userDetailsReducer, userUpdateProfileReducer} from "./reducers/userReducer";
import {contributionListReducer, contributionCreateReducer, contributionUpdateReducer, contributionDeleteReducer, contributionDetailsReducer} from "./reducers/contributionReducer";
import {eventCreateReducer, eventDeleteReducer, eventDetailsReducer, eventListReducer, eventUpdateReducer} from "./reducers/eventReducer";
import { jengaApiConfigReducer } from "./reducers/jengaApiReducers";

const reducers = combineReducers({
 
    userLogin: userLoginReducer,
    userRegister: userRegisterReducer,
    userDetails: userDetailsReducer,
    userUpdateProfile: userUpdateProfileReducer,
    userList: userListReducer,
    userDelete: userDeleteReducer,
    userUpdate: userUpdateReducer,
    contributionList: contributionListReducer,
    contributionCreate: contributionCreateReducer,
    contributionUpdate: contributionUpdateReducer,
    contributionDelete: contributionDeleteReducer,
    contributionDetails: contributionDetailsReducer,
    eventList: eventListReducer,
    eventCreate: eventCreateReducer,
    eventUpdate: eventUpdateReducer,
    eventDelete: eventDeleteReducer,
    eventDetails: eventDetailsReducer,
    jengaApiConfig: jengaApiConfigReducer,
  

  
});


const userInfoFromStorage = localStorage.getItem("userInfo")
  ? JSON.parse(localStorage.getItem("userInfo"))
  : null;


const initialState = {
 
  userLogin: { userInfo: userInfoFromStorage }
};

const middleware = [thunk];
// Redux DevTools enhancer setup with trace option enabled
const composeEnhancers = composeWithDevTools({
  trace: true // Enable trace option for action calls
});

const store = createStore(
  reducers,
  initialState,
  composeEnhancers(applyMiddleware(...middleware))
);

export default store;
