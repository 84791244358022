import {
    JENGA_API_CONFIG_CREATE_REQUEST,
    JENGA_API_CONFIG_CREATE_SUCCESS,
    JENGA_API_CONFIG_CREATE_FAIL,
    JENGA_API_CONFIG_GET_REQUEST,
    JENGA_API_CONFIG_GET_SUCCESS,
    JENGA_API_CONFIG_GET_FAIL,
    JENGA_API_CONFIG_UPDATE_REQUEST,
    JENGA_API_CONFIG_UPDATE_SUCCESS,
    JENGA_API_CONFIG_UPDATE_FAIL,
  } from '../constants/jengaApiConstants';
  
  export const jengaApiConfigReducer = (state = {}, action) => {
    switch (action.type) {
      case JENGA_API_CONFIG_CREATE_REQUEST:
        return { loading: true };
      case JENGA_API_CONFIG_CREATE_SUCCESS:
        return { loading: false, success: true, config: action.payload };
      case JENGA_API_CONFIG_CREATE_FAIL:
        return { loading: false, error: action.payload };
      case JENGA_API_CONFIG_GET_REQUEST:
        return { ...state, loading: true };
      case JENGA_API_CONFIG_GET_SUCCESS:
        return { loading: false, config: action.payload };
      case JENGA_API_CONFIG_GET_FAIL:
        return { loading: false, error: action.payload };
      case JENGA_API_CONFIG_UPDATE_REQUEST:
        return { ...state, loading: true };
      case JENGA_API_CONFIG_UPDATE_SUCCESS:
        return { loading: false, success: true, config: action.payload };
      case JENGA_API_CONFIG_UPDATE_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };