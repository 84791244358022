import {
  CONTRIBUTION_LIST_REQUEST,
  CONTRIBUTION_LIST_SUCCESS,
  CONTRIBUTION_LIST_FAIL,
  CONTRIBUTION_DETAILS_REQUEST,
  CONTRIBUTION_DETAILS_SUCCESS,
  CONTRIBUTION_DETAILS_FAIL,
  CONTRIBUTION_CREATE_REQUEST,
  CONTRIBUTION_CREATE_SUCCESS,
  CONTRIBUTION_CREATE_FAIL,
  CONTRIBUTION_CREATE_RESET,
  CONTRIBUTION_UPDATE_REQUEST,
  CONTRIBUTION_UPDATE_SUCCESS,
  CONTRIBUTION_UPDATE_FAIL,
  CONTRIBUTION_UPDATE_RESET,
  CONTRIBUTION_DELETE_REQUEST,
  CONTRIBUTION_DELETE_SUCCESS,
  CONTRIBUTION_DELETE_FAIL
} from "../constants/contributionConstants";

export const contributionListReducer = (
  state = { contributions: [] },
  action
) => {
  switch (action.type) {
    case CONTRIBUTION_LIST_REQUEST:
      return { loading: true, contributions: [] };
    case CONTRIBUTION_LIST_SUCCESS:
      return { loading: false, contributions: action.payload };
    case CONTRIBUTION_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const contributionDetailsReducer = (
  state = { contribution: {} },
  action
) => {
  switch (action.type) {
    case CONTRIBUTION_DETAILS_REQUEST:
      return { loading: true, ...state };
    case CONTRIBUTION_DETAILS_SUCCESS:
      return { loading: false, contribution: action.payload };
    case CONTRIBUTION_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const contributionCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case CONTRIBUTION_CREATE_REQUEST:
      return { loading: true };
    case CONTRIBUTION_CREATE_SUCCESS:
      return { loading: false, success: true, contribution: action.payload };
    case CONTRIBUTION_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case CONTRIBUTION_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

export const contributionUpdateReducer = (
  state = { contribution: {} },
  action
) => {
  switch (action.type) {
    case CONTRIBUTION_UPDATE_REQUEST:
      return { loading: true };
    case CONTRIBUTION_UPDATE_SUCCESS:
      return { loading: false, success: true, contribution: action.payload };
    case CONTRIBUTION_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case CONTRIBUTION_UPDATE_RESET:
      return { contribution: {} };
    default:
      return state;
  }
};

export const contributionDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case CONTRIBUTION_DELETE_REQUEST:
      return { loading: true };
    case CONTRIBUTION_DELETE_SUCCESS:
      return { loading: false, success: true };
    case CONTRIBUTION_DELETE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};