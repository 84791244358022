import React from "react";
import {
  Box,
  Heading,
  Text,
  Button,
  Stack,
  Image,
  Flex,
  Icon,
  SimpleGrid,
  Avatar,
  Container,
  VStack,
  useBreakpointValue,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { FaUser, FaChartBar, FaCalendar, FaQuoteLeft } from "react-icons/fa";

const HomeScreen = () => {
  const heroImageSize = useBreakpointValue({ base: "300px", md: "400px" });
  const heroTextMaxW = useBreakpointValue({ base: "100%", md: "50%" });
  const sectionPadding = useBreakpointValue({ base: 10, md: 20 });

  return (
    <Box bg="gray.50" minHeight="100vh">
      {/* Hero Section */}
      <Box bg="teal.500" color="white" py={sectionPadding}>
        <Container maxW="container.xl">
          <Flex direction={{ base: "column", md: "row" }} align="center" justify="space-between">
            <VStack align={{ base: "center", md: "flex-start" }} spacing={6} maxW={heroTextMaxW} mb={{ base: 8, md: 0 }}>
              <Heading as="h1" size={{ base: "xl", md: "2xl" }} textAlign={{ base: "center", md: "left" }}>
                Welcome to the Estate Welfare Group
              </Heading>
              <Text fontSize={{ base: "lg", md: "xl" }} textAlign={{ base: "center", md: "left" }}>
                Fostering community, promoting well-being, and ensuring smooth estate management through collaborative efforts.
              </Text>
              <Stack direction={{ base: "column", sm: "row" }} spacing={4} width="100%">
                <Button as={Link} to="#about" colorScheme="whiteAlpha" size={{ base: "md", md: "lg" }} width={{ base: "100%", sm: "auto" }}>
                  About Us
                </Button>
                <Button as={Link} to="#features" colorScheme="whiteAlpha" size={{ base: "md", md: "lg" }} width={{ base: "100%", sm: "auto" }}>
                  Key Features
                </Button>
              </Stack>
              <Stack direction={{ base: "column", sm: "row" }} spacing={4} width="100%">
                <Button as={Link} to="#values" colorScheme="whiteAlpha" size={{ base: "md", md: "lg" }} width={{ base: "100%", sm: "auto" }}>
                  Our Core Values
                </Button>
                <Button as={Link} to="#team" colorScheme="whiteAlpha" size={{ base: "md", md: "lg" }} width={{ base: "100%", sm: "auto" }}>
                  The Team
                </Button>
              </Stack>
            </VStack>
            <Image
              src="/img/community.jpg"
              alt="Community"
              borderRadius="md"
              boxSize={heroImageSize}
              objectFit="cover"
              display={{ base: "none", md: "block" }}
            />
          </Flex>
        </Container>
      </Box>

      {/* About Section */}
      <Box py={sectionPadding} id="about">
        <Container maxW="container.xl">
          <Heading as="h2" size={{ base: "lg", md: "xl" }} mb={{ base: 6, md: 10 }} textAlign="center" color="teal.600">
            About Estate Welfare Group
          </Heading>
          <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
            <Box>
              <Text fontSize={{ base: "md", md: "lg" }} color="gray.600" mb={4}>
                The Estate Welfare Group is a dedicated organization committed to promoting the well-being and harmony of our community. We strive to create a vibrant and inclusive environment where residents can thrive and build strong connections with their neighbors.
              </Text>
              <Text fontSize={{ base: "md", md: "lg" }} color="gray.600">
                Through various initiatives, events, and collaborative efforts, we aim to foster a sense of belonging, address the needs of our residents, and maintain the overall aesthetics and functionality of our estate.
              </Text>
            </Box>
            <Image
              src="/img/about-us.jpg"
              alt="About Us"
              borderRadius="md"
              objectFit="cover"
              boxShadow="lg"
              width="100%"
              height={{ base: "200px", md: "auto" }}
            />
          </SimpleGrid>
        </Container>
      </Box>

      {/* Key Features Section */}
      <Box bg="gray.100" py={sectionPadding} id="features">
        <Container maxW="container.xl">
          <Heading as="h2" size={{ base: "lg", md: "xl" }} mb={{ base: 6, md: 10 }} textAlign="center" color="teal.600">
            Key Features
          </Heading>
          <SimpleGrid columns={{ base: 1, md: 3 }} spacing={{ base: 6, md: 10 }}>
            <FeatureCard
              icon={FaUser}
              title="User Management"
              description="Easily manage user accounts, roles, and permissions."
            />
            <FeatureCard
              icon={FaChartBar}
              title="Financial Tracking"
              description="Track and manage financial contributions and expenses."
            />
            <FeatureCard
              icon={FaCalendar}
              title="Event Management"
              description="Plan and organize community events and activities."
            />
          </SimpleGrid>
        </Container>
      </Box>

      {/* Core Values Section */}
      <Box py={sectionPadding} id="values">
        <Container maxW="container.xl">
          <Heading as="h2" size={{ base: "lg", md: "xl" }} mb={{ base: 6, md: 10 }} textAlign="center" color="teal.600">
            Our Core Values
          </Heading>
          <SimpleGrid columns={{ base: 1, md: 3 }} spacing={{ base: 6, md: 10 }}>
            <ValueCard
              title="Community"
              description="We believe in the power of a strong and connected community, where individuals support and uplift each other."
            />
            <ValueCard
              title="Inclusivity"
              description="We embrace diversity and strive to create an inclusive environment where everyone feels welcomed and valued."
            />
            <ValueCard
              title="Collaboration"
              description="We believe in the power of collaboration and actively seek opportunities to work together towards common goals."
            />
          </SimpleGrid>
        </Container>
      </Box>

      {/* Team Section */}
      <Box bg="gray.100" py={sectionPadding} id="team">
        <Container maxW="container.xl">
          <Heading as="h2" size={{ base: "lg", md: "xl" }} mb={{ base: 6, md: 10 }} textAlign="center" color="teal.600">
            Meet Our Team
          </Heading>
          <SimpleGrid columns={{ base: 1, sm: 2, md: 3 }} spacing={{ base: 6, md: 10 }}>
            <TeamMemberCard
              name="John Doe"
              role="Chairman"
              imageSrc="/img/JohnDoe.jpg"
            />
            <TeamMemberCard
              name="Jane Smith"
              role="Secretary"
              imageSrc="/img/JaneSmith.jpg"
            />
            <TeamMemberCard
              name="Michael Johnson"
              role="Treasurer"
              imageSrc="/img/MichaelJohnson.jpg"
            />
          </SimpleGrid>
        </Container>
      </Box>
    </Box>
  );
};

const FeatureCard = ({ icon, title, description }) => (
  <Box 
    bg="white" 
    p={{ base: 4, md: 6 }}
    borderRadius="lg" 
    boxShadow="md"
    transition="all 0.3s"
    _hover={{ transform: "translateY(-5px)", boxShadow: "lg" }}
    textAlign="center"
  >
    <Icon as={icon} color="teal.500" boxSize={{ base: 8, md: 12 }} mb={4} />
    <Heading as="h3" size={{ base: "md", md: "lg" }} color="teal.500" mb={4}>
      {title}
    </Heading>
    <Text fontSize={{ base: "sm", md: "md" }} color="gray.600">
      {description}
    </Text>
  </Box>
);

const ValueCard = ({ title, description }) => (
  <Box bg="white" p={{ base: 4, md: 6 }} borderRadius="md" boxShadow="md" textAlign="center">
    <Icon as={FaQuoteLeft} color="teal.500" boxSize={{ base: 6, md: 8 }} mb={4} />
    <Heading as="h3" size={{ base: "md", md: "lg" }} mb={2} color="teal.500">
      {title}
    </Heading>
    <Text fontSize={{ base: "sm", md: "md" }} color="gray.600">
      {description}
    </Text>
  </Box>
);

const TeamMemberCard = ({ name, role, imageSrc }) => (
  <Box bg="white" p={{ base: 4, md: 6 }} borderRadius="md" boxShadow="md" textAlign="center">
    <Avatar src={imageSrc} size={{ base: "xl", md: "2xl" }} mx="auto" mb={4} />
    <Heading as="h3" size={{ base: "md", md: "lg" }} mb={2} color="teal.500">
      {name}
    </Heading>
    <Text fontSize={{ base: "sm", md: "md" }} color="gray.600">
      {role}
    </Text>
  </Box>
);

export default HomeScreen;